import { propertyFolder } from '../model/field';
import { generateVariationWatchFromFieldDefn } from './generate-variation-paths-tools';

export const vendorNodesWhitelistForContractVariation = generateVariationWatchFromFieldDefn(propertyFolder.vendors, ['vendors'])
  .filter(ps => !['email1', 'email2', 'phone1', 'phone2', 'id', 'email', 'phone'].includes(ps[ps.length-1]));

export const saleContractNonUniqueWatchPaths = {
  assocAgent: [
    ['authAssoc'],
    ['assocAgents']
  ],
  property: [
    ['saleAddrs'],
    ['saleTitles'],
    ['titleDivision']
  ],
  vendor: [...vendorNodesWhitelistForContractVariation, ['primaryVendor']],
  purchaser: [
    ['purchasers'],
    ['primaryPurchaser']
  ],
  contractGst: [
    ['vendorGst'],
    ['contractPrice', 'inputTaxed'],
    ['contractPrice', 'taxableSupply'],
    ['contractPrice', 'gstIncluded'],
    ['contractPrice', 'marginApplied']
  ],
  contractPrice: [
    ['contractPrice', 'purchasePrice'],
    ['contractPrice', 'purchaseGst'],
    ['contractPrice', 'deposit'],
    ['contractPrice', 'depositPayAt'],
    ['contractPrice', 'depositDateOther']
  ],
  contractSettlement: [['contractSettlement']],
  contractSchedule: [
    ['chattels'],
    ['exclusions'],
    ['saaTenant', 'tenantEnable'],
    ['tenantsCollect'],
    ['encroach'],
    ['encroachEnable'],
    ['notiWorks'],
    ['notiWorksEnable'],
    ['vendorWorks'],
    ['vendorWorksEnable'],
    ['gstWithholdEnable'],
    ['pool'],
    ['contractSchedule', 'noForm1NoCoolMatters']
  ],
  legacyContractSpecialGeneral: [
    ['contractSpecial', 'hideSection']
  ],
  contractSpecialFinance: [
    ['contractSpecial', 'financeRequired'],
    ['contractSpecial', 'financeDeadline'],
    ['contractSpecial', 'financeDeadlineTime'],
    ['contractSpecial', 'financeAmount'],
    ['contractSpecial', 'financeInterestRate'],
    ['contractSpecial', 'financeProperties'],
    ['contractSpecial', 'hideFinance']
  ],
  contractSpecialPurchaserSale: [
    ['contractSpecial', 'purchaserSaleRequired'],
    ['contractSpecial', 'purchaserSaleContractDeadline'],
    ['contractSpecial', 'purchaserSaleSettlementDeadline'],
    ['contractSpecial', 'saleSettleAlsoRequired'],
    ['contractSpecial', 'salePropertySingleLine'],
    ['contractSpecial', 'purchaserSaleMinimumPrice'],
    ['contractSpecial', 'hideSaleRequired']
  ],
  contractOther: [['contractOther']]
};

export const saleContractNonUniqueWatchPathsFlat = Object.values(saleContractNonUniqueWatchPaths).flat();
