import { partySection } from '@property-folders/common/util/pdfgen/sections/vendorSection';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import { Predicate } from '@property-folders/common/predicate';
import { variationWrapContentInPage } from '@property-folders/common/util/pdfgen/variations/outer-skeleton';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { ExtraFormCode, FormCode, MarketingTemplateOld, SAAVerbosity } from '@property-folders/contract';
import { buildSignatureSection } from '@property-folders/common/util/pdf-worker/buildSignatureSection';
import { PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { renderIfVariation } from '@property-folders/common/util/pdfgen/sections/variations/renderIfVariation';
import { variationItem } from '@property-folders/common/util/pdfgen/variations/variation-li';
import { topLevelItemTextGen } from '@property-folders/common/util/pdfgen/formatters/topLevelItemTextGen';
import { sectionTitles as st, subsectionTitles as sub } from '@property-folders/common/util/pdfgen/doc-constants/sales-agency-agreement';
import { vendorAddressChangeIfSameAs } from '@property-folders/common/util/variations/vendorAddressChangeIfSameAs';
import { agentSection } from '@property-folders/common/util/pdfgen/sections/agentSections';
import { saleMethodSection, saleSection } from '@property-folders/common/util/pdfgen/sections/saleSection';
import {
  chattelsSection, encroachSection,
  exclusionSection, gstWithholdingSection, notiWorksSection,
  poolSection,
  tenantSection, vendorGstSection, vendorWorksSection
} from '@property-folders/common/util/pdfgen/sections/particularsSection';
import { subItemTextGen } from '@property-folders/common/util/pdfgen/formatters/subItemTextGen';
import { LegalJurisdiction, chattelsOptionsSaa } from '@property-folders/common/util/pdfgen/constants';
import {
  adminFeeSection,
  auctionFeeSection,
  benefitsSection,
  marketingCostSection,
  pressSection,
  shouldDisplayVpaSection,
  vpaCampaignAgentSection
} from '@property-folders/common/util/pdfgen/sections/feeSection';
import { professionalFeeSection } from '@property-folders/common/util/pdfgen/sections/professionalFeeSection';
import {
  buildClauseAndAnnexureDiffDisplay
} from '@property-folders/common/util/pdfgen/sections/buildClauseAndAnnexureDiffDisplay';
import { formatBI } from '../../formatBI';
import { formatAct, ol, stack } from '../../formatters/clauses';
import { settlementSubsection } from '../../sections/contractSettlement';
import { marketingTemplateSection, rayWhiteMarketingTemplateSection } from '../../sections/marketingTemplateSection';
import { Content } from 'pdfmake/interfaces';

const thisFormCode = ExtraFormCode.AAV_SalesAgencyAgreementVariation;
const title = FormTypes[thisFormCode].label;

export function salesAgreementVariation({
  property,
  signers,
  previousFormInstance,
  brand,
  objects,
  propertyRaw,
  snapshotHistory,
  changeSet,
  lastSignedSnapData,
  annexures,
  memberEntities,
  currentFormInstance
}: PdfWorkerDocumentDefinition) {
  const extraContent: Content[] = [];
  buildClauseAndAnnexureDiffDisplay('clauses', snapshotHistory, changeSet, propertyRaw, annexures, extraContent);
  let vendorPartyLabel = st.vendors.title.toLocaleLowerCase();
  vendorPartyLabel = vendorPartyLabel[0].toLocaleUpperCase() + vendorPartyLabel.slice(1);

  const signatureSection = buildSignatureSection(signers, previousFormInstance);

  const mainAgent = property?.agent?.[0];
  const currentEntity = memberEntities[`${mainAgent?.linkedEntityId}`];
  const tradeName = currentEntity?.tradeName??brand.agencyContact?.agencyName ?? 'the Agency';

  const shortMode = currentFormInstance?.unsignedDenormals?.saaVerbosity === SAAVerbosity.short;
  const variationContent = [
    renderIfVariation(()=>{
      return variationItem(topLevelItemTextGen(st.properties),
        PdfGen.propertySection({
          itemNo: st.properties.num,
          addresses: Object.values(property?.saleAddrs ?? []),
          titles: property?.saleTitles ?? [],
          titleDivision: property?.titleDivision ?? {},
          proposedLots: property?.proposedLots,
          isVariation: true,
          annexures,
          divisionPlanOptional: true,
          shortMode
        })
      );
    },
    [['saleAddrs'], ['saleTitles'], ['titleDivision']],
    changeSet
    ),
    renderIfVariation(()=>{
      const result = variationItem(topLevelItemTextGen(st.vendors),
        partySection(
          st.vendors.num,
          Object.values(property?.vendors ?? []),
          property?.saleAddrs,
          property?.primaryVendor,
          vendorPartyLabel,
          false,
          false,
          'vendors',
          true
        ),
      );
      return result;
    },
    [['vendors'], ['primaryVendor']],
    changeSet,
    property && lastSignedSnapData && vendorAddressChangeIfSameAs(property, lastSignedSnapData)
    ),
    renderIfVariation(()=>{
      return variationItem(topLevelItemTextGen(st.agent),
        agentSection(
          st.agent.num,
          property?.agent||[],
          property?.authAssoc,
          property?.assocAgents,
          property?.agencyExclusive,
          property?.agency,
          property?.otherAgents,
          property?.prevAgentEnable,
          property?.prevAgentConfirm,
          property?.prevAgents,
          property?.prevAgentExpire,
          property?.prevAgentNotice,
          property?.agentAuthority,
          { isVariation: true, memberEntities, shortMode }
        )
      );
    },
    [
      ['agent', '[*]', 'linkedEntityId'],
      ['agent', '[*]', 'salesp', '[*]', 'linkedSalespersonId'],
      ['authAssoc'],
      ['assocAgents'],
      ['agencyExclusive'],
      ['agency'],
      ['otherAgents'],
      ['prevAgentEnable'],
      ['prevAgentConfirm'],
      ['prevAgents'],
      ['prevAgentExpire'],
      ['prevAgentNotice'],
      ['agentAuthority']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(topLevelItemTextGen(st.sale),
        saleSection(st.sale.num,
          property?.sale,
          property?.compareAddrs,
          property?.disableCompareAlreadyProvided,
          { annexureRef: property?.comparableSales?.annexureRef?.['family_'+FormCode.RSAA_SalesAgencyAgreement], annexures, isVariation: true, shortMode }
        )
      );
    },
    [
      ['sale', 'advertRange'],
      ['sale', 'advertPrc'],
      ['sale', 'advertPrcUpper'],
      ['sale', 'vendorPrc'],
      ['sale', 'agentEstPrc'],
      ['compareAddrs'],
      ['disableCompareAlreadyProvided']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(topLevelItemTextGen(st.saleMethod),
        saleMethodSection(
          st.saleMethod.num,
          property?.sale?.saleMethod,
          property?.sale?.saleMethodOther,
          property?.auction,
          { isVariation: true, shortMode }
        )
      );
    },
    [
      ['sale', 'saleMethod'], ['sale', 'saleMethodOther'], ['auction']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.settlePeriod),
        settlementSubsection(property?.contractSettlement, { isVariation: true, showTitle: true, isSaa: true, shortMode })
      );
    },
    [
      ['contractSettlement']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.inclusions),
        chattelsSection(property?.chattels, chattelsOptionsSaa, true)
      );
    },
    [
      ['chattels']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.exclusions),
        exclusionSection(property?.exclusions, true)
      );
    },
    [
      ['exclusions']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.tenant),
        tenantSection(property?.saaTenant, property?.tenantsCollect,false, true)
      );
    },
    [
      ['saaTenant', 'tenantEnable'],
      ['tenantsCollect']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.encroach),
        encroachSection({ enable: property?.encroachEnable, content: property?.encroach, isVariation: true })
      );
    },
    [
      ['encroach'],
      ['encroachEnable']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.notiWorks),
        notiWorksSection({ enable: property?.notiWorksEnable, content: property?.notiWorks, isVariation: true })
      );
    },
    [
      ['notiWorks'],
      ['notiWorksEnable']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.vendorWorks),
        vendorWorksSection({ enable: property?.vendorWorksEnable , content: property?.vendorWorks, isVariation: true })
      );
    },
    [
      ['vendorWorks'],
      ['vendorWorksEnable']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.gstWith),
        gstWithholdingSection({ gstWithholdEnable: property?.gstWithholdEnable }, { isVariation: true, shortMode })
      );
    },
    [
      ['gstWithholdEnable']

    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.pool),
        poolSection(
          property?.pool?.present,
          property?.pool?.complyState,
          property?.pool?.nonComplyWorks,
          { isVariation: true, saaMode: true }
        )
      );
    },
    [
      ['pool']

    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.particulars, sub.gstIssues),
        vendorGstSection({
          vendorGst: property?.vendorGst,
          inputTaxed: property?.contractPrice?.inputTaxed,
          saaGstUnknowns: property?.saaGstUnknowns
        }, {
          isVariation: true
        })
      );
    },
    [
      ['vendorGst'],
      ['contractPrice', 'inputTaxed']
    ],
    changeSet
    ),
    //Old marketing template
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.marketingCosts),
        'enable' in (property.marketingTemplate||{}) && (property.marketingTemplate as MarketingTemplateOld).enable
          ? rayWhiteMarketingTemplateSection(property.marketingTemplate as MarketingTemplateOld, property.marketingFees, brand, true)
          : typeof property.marketingTemplate?.id === 'string'
            ? marketingTemplateSection(property.marketingTemplate, true)
            : marketingCostSection({ marketingFees: property?.marketingFees, marketingFeesOptions: property?.marketingFeesOptions, formCode: thisFormCode, annexures }, true)
      );
    },
    [
      ['marketingFees'],
      ['marketingTemplate']
    ],
    changeSet
    ),
    !shortMode && renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.periodicPress),
        pressSection(property?.pressBudget, { isVariation: true, shortMode })
      );
    },
    [
      ['pressBudget']
    ],
    changeSet
    ),
    shouldDisplayVpaSection({ vpa: property?.marketingFeesOptions?.vendorPaidAdvertising, entitySettings: currentEntity }) && renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.periodicPress),
        vpaCampaignAgentSection({ vpa: property?.marketingFeesOptions?.vendorPaidAdvertising, entitySettings: currentEntity, tradeName })
      );
    },
    [
      ['marketingFeesOptions', 'vendorPaidAdvertising']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.adminFee),
        adminFeeSection(property?.adminFee, true)
      );
    },
    [
      ['adminFee']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.auctionFee),
        auctionFeeSection(
          {
            saleMethod: property?.sale?.saleMethod,
            auctionFee: property?.auctionFee,
            auctionFeeSwitches: property?.auctionFeeSwitches
          },
          { isVariation: true }
        )
      );
    },
    [
      ['auctionFee']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(subItemTextGen(st.marketing, sub.disclosureOfBenefits),
        benefitsSection(
          {
            benefits: property?.benefits
          },
          { isVariation: true }
        )
      );
    },
    [
      ['benefits']
    ],
    changeSet
    ),
    renderIfVariation(()=>{
      return variationItem(topLevelItemTextGen(st.professionalFee),
        professionalFeeSection(
          st.professionalFee.num,
          [],
          {},
          [],
          property?.professFee,
          property?.uplift,
          {},
          '',
          '',
          property?.payTerm,
          true
        )
      );
    },
    [
      ['professFee'],
      ['uplift'],
      ['payTerm']
    ],
    changeSet
    ),
    ...extraContent

  ].filter(Predicate.isTruthy);

  const electronicSigningTerms = stack(
    'The Parties acknowledge and agree to:',
    ol(
      'the signing by a party of this Variation and any other documents by digital or electronic signatures; and',
      formatBI(`all communications relating to the sale of the Property (including the service of any notices under the Act) by way of electronic means via the party’s email address or mobile phone number specified in the Agreement or varied by this Variation or as otherwise provided to the other party from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')}.`)
    )
  );

  const counterpartsTerms = 'This Variation may be executed in any number of counterparts, and by a party on separate counterparts. All counterparts taken together constitute one and the same instrument.';

  return variationWrapContentInPage(
    variationContent,
    objects?.agencyLogoImage,
    title,
    brand,
    signatureSection,
    propertyRaw,
    snapshotHistory,
    FormTypes[thisFormCode],
    signers,
    {
      alternativeElectronicSigningTerms: electronicSigningTerms,
      alternativeCounterpartsTerms: counterpartsTerms,
      clause4ConsiderationText: 'The parties acknowledge and agree that this Variation is entered into in consideration of the mutual commitments contained in this Variation, the amounts paid by the Vendor to the Agent under the Agreement, and the ongoing provision of services pursuant to the Agreement as varied by this Variation.',
      currentEntity
    }
  );

}
